import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { useMatch, useNavigate } from 'react-router-dom'
import sidebarItems from '../data/sidebar'

export default function MenuContent () {
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {sidebarItems.map((item, index) => (
          <NavItem item={item} key={index} />
        ))}
      </List>
    </Stack>
  )
}

function NavItem ({ item }) {
  const navigate = useNavigate()
  const match = useMatch(`/${item.path}/*`)

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        selected={match}
        onClick={() => navigate(item.path)}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  )
}
