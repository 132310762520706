import { createBrowserRouter, redirect } from 'react-router-dom'
import AppV2 from './AppV2'
import { getChannels } from './api'

const tokenKey = '_revere_authToken'

const router = createBrowserRouter([
  {
    path: '/login',
    loader: async () => {
      try {
        const token = localStorage.getItem(tokenKey)
        if (!token) return null

        await getChannels()
        return redirect('/')
      } catch (error) {
        localStorage.removeItem(tokenKey)
        return null
      }
    },
    lazy: () => import('./pages/SignIn')
  },
  {
    path: '',
    Component: AppV2,
    shouldRevalidate: () => false,
    loader: async ({ request }) => {
      const url = new URL(request.url)

      try {
        const qsToken = url.searchParams.get('jwt')
        if (qsToken) {
          localStorage.setItem(tokenKey, qsToken)
          url.searchParams.delete('jwt')
          await getChannels()
          window.history.replaceState({}, '', url)
          return null
        }

        const token = localStorage.getItem(tokenKey)
        if (!token) return redirect('/login')
        await getChannels()
        return null
      } catch (error) {
        localStorage.removeItem(tokenKey)
        return redirect('/login')
      }
    },
    children: [
      {
        path: 'documentation',
        id: 'documentation',
        lazy: () => import('./pages/Documentation')
      },
      {
        path: '',
        id: 'channels',
        lazy: () => import('./pages/Channels'),
        children: [
          {
            path: ':channelId/summary/:frequency',
            id: 'channel-summary',
            lazy: () => import('./pages/Channels/Summary')
          }
        ]
      },
      {
        path: '*',
        element: <div>Not Found</div>
      }
    ]
  }
])

export default router
