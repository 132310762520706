import axios from 'axios'
import config from './config'

export const AUTHENTIC_SERVER = process.env.REACT_APP_AUTHENTIC_SERVER ||
'https://auth.aiinkwell.app'

export const API_SERVER = config.apiServer ||
  process.env.REACT_APP_API_SERVER ||
  'https://revere.aiinkwell.app'

const headers = () => {
  const tokenKey = '_revere_authToken'
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(tokenKey)}`
  }
}

export const getSetupInstructions = async () => {
  try {
    const response = await axios.get(
      `${API_SERVER}/setup-instructions`,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching setup instructions:', error)
    throw error
  }
}

export const getChannels = async () => {
  try {
    const response = await axios.get(
      `${API_SERVER}/api/slack/channels`,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching channels:', error)
    throw error
  }
}

export const updateSubscriptions = async (data) => {
  try {
    const response = await axios.put(
      `${API_SERVER}/api/slack/subscriptions`,
      data,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error updating subscriptions:', error)
    throw error
  }
}

export const getSummaries = async () => {
  try {
    const response = await axios.get(
      `${API_SERVER}/api/slack/channel-summaries`,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching channels:', error)
    throw error
  }
}
