import {
  QueryClient,
  QueryClientProvider as Provider
} from '@tanstack/react-query'

export const queryClient = new QueryClient()

export function QueryClientProvider ({ children }) {
  return <Provider client={queryClient}>{children}</Provider>
}
