import { SvgIcon } from '@mui/material'

export default function InkwellLogo ({ ...props }) {
  return (
    <SvgIcon viewBox='0 0 256 256' {...props}>
      <path d='M125.656 13L129.444 13.4198L132.812 15.519L136.179 20.977L139.546 27.6944L143.335 34.4119L150.069 44.488L155.541 52.0451L162.276 60.8618L168.169 68.8388L173.641 76.3959L177.43 83.1133L179.955 89.4109L181.639 96.1284L182.481 102.846V110.823L181.639 116.701L179.534 122.998L176.588 128.456L173.641 132.235L169.853 134.754L163.539 136.433L153.016 138.112L148.386 139.792L145.439 141.891L143.756 144.41L142.914 147.769V155.746L143.335 160.784L149.228 155.746L153.437 151.967L157.225 149.448L161.014 149.028L162.697 150.708V153.227L161.014 155.326L155.121 159.944L149.649 164.982L146.281 168.761L142.914 174.219L141.23 178.417L140.809 178.837H134.916L134.495 178.417V128.456L135.758 125.097L137.442 122.998L140.809 121.319L147.965 120.059L153.016 117.54L155.541 115.441L158.067 111.243L159.33 106.624V99.4871L157.646 93.1895L155.121 87.3117L150.069 79.7546L144.597 72.6173L137.863 63.8007L131.128 54.1443L127.34 48.2666V47.4269L125.656 49.1063L118.921 59.1824L114.712 65.0602L108.819 73.0372L103.768 79.7546L99.1376 87.3117L95.7702 94.449L94.9283 97.8077V103.685L96.612 108.304L98.7166 111.243L98.2957 112.502L89.0354 117.54L83.9843 121.319L80.196 126.777L78.5123 129.716L76.8286 128.876L74.303 124.678L73.0402 121.319L71.3565 112.922L70.9356 104.945L71.7775 96.5482L74.303 87.7316L78.5123 78.9149L82.7215 72.6173L88.1935 65.0602L94.9283 56.2435L99.1376 50.3658L105.031 42.3888L111.344 31.8928L116.816 21.8166L119.763 16.7786L123.13 13.8397L125.656 13Z' />
      <path d='M107.933 122.571H113.408L117.62 124.655L119.305 127.572V178.42L118.884 178.837H113.408L111.302 175.503L108.775 170.918L104.985 166.333L99.5096 161.332L94.8767 157.581L91.9285 154.663L88.9803 150.079L87.7167 145.911V141.326L88.9803 137.158L91.5073 132.99L95.2979 128.823L98.6672 126.322L103.721 123.821L107.933 122.571Z' />
      <path d='M127.327 190.682H135.996L138.06 191.466V207.526L136.409 210.66L134.345 212.619L131.043 214.577L128.153 215.361H124.438L120.31 213.794L117.833 211.835L115.769 208.31L115.356 206.351V191.466L115.769 191.074L127.327 190.682Z' />
      <path d='M123.639 83.0858H126.97L131.551 84.3623L135.299 86.9152L137.798 90.3191L139.047 93.723V98.4033L137.381 101.807L134.883 104.786L131.135 106.913L127.387 107.764L122.389 107.338L118.225 105.637L115.31 103.084L112.811 98.4033L112.395 94.1484L113.644 90.3191L116.143 86.9152L119.474 84.3623L123.639 83.0858Z' />
      <path d='M137.073 227.206H140.262L142.416 236.113L143.278 239.694L143.967 237.031L146.208 227.941L146.466 227.206H149.655L149.913 228.032L152.413 238.592L152.585 239.235V239.694H152.757L152.843 238.96L155.256 228.951L155.687 227.206H158.79L157.669 231.063L155.773 237.582L154.222 242.908L154.136 243H150.948L150.689 242.265L147.931 231.522L146.38 237.582L145.001 242.908L144.915 243H141.727L141.468 242.357L139.572 235.838L137.59 229.134L137.073 227.39V227.206Z' />
      <path d='M72.0231 227.206H73.6962L74.951 227.472L75.7876 227.827L76.7078 228.448L77.7116 229.424L77.7953 227.472H80.8069V242.734H77.879L77.7953 242.645V240.604L76.8751 241.58L75.7876 242.29L74.7837 242.734L73.3616 243H72.2741L71.1866 242.823L70.0154 242.379L69.0116 241.758L68.0077 240.782L67.4221 240.072L66.6692 238.652L66.251 237.321L66.0837 236.345L66 234.926L66.1673 233.151L66.5856 231.643L67.2548 230.312L67.9241 229.424L68.5933 228.714L69.7645 227.916L70.7683 227.472L72.0231 227.206ZM72.776 229.957L71.6885 230.312L70.852 230.844L70.35 231.288L69.5971 232.441L69.2625 233.328L69.0952 234.393V235.724L69.3462 236.966L70.0154 238.386L70.601 239.096L71.4376 239.717L72.5251 240.161L73.027 240.249H73.9472L74.951 239.983L75.9549 239.451L76.4568 239.007L77.2097 237.942L77.628 236.878L77.7953 236.079V234.038L77.5443 232.973L76.9588 231.82L76.2059 230.933L75.453 230.4L74.5328 230.045L74.1145 229.957H72.776Z' />
      <path d='M167.296 227.206H169.213L170.606 227.472L171.738 227.916L172.784 228.537L173.829 229.513L174.613 230.578L175.222 231.997L175.484 233.062L175.571 233.683V235.902L175.484 236.256H164.074L164.248 237.321L164.596 238.209L165.206 239.007L165.816 239.54L166.687 239.983L167.209 240.161L167.819 240.249H168.777L169.648 240.072L170.867 239.451L171.477 238.741L171.826 238.209H175.135L175.048 238.652L174.439 239.894L173.655 240.87L172.871 241.58L171.738 242.29L170.519 242.734L169.735 242.911L168.951 243H167.645L166.425 242.823L165.119 242.379L163.987 241.758L163.116 241.048L162.506 240.427L161.722 239.185L161.287 238.209L160.938 236.966L160.764 235.28L160.851 233.683L161.199 232.086L161.722 230.933L162.245 230.045L163.203 228.981L164.248 228.182L165.38 227.65L166.687 227.295L167.296 227.206ZM167.471 229.868L166.338 230.223L165.554 230.755L164.858 231.465L164.422 232.175L164.074 233.328V233.683H172.348L172.261 232.707L171.826 231.643L171.129 230.844L170.345 230.312L169.387 229.957L168.864 229.868H167.471Z' />
      <path d='M124.327 221.283H127.362L127.449 233.733L128.229 232.703L129.183 231.486L129.963 230.457L130.917 229.24L131.61 228.304L132.564 227.087L132.738 226.806H137.073L136.553 227.461L133.691 230.831L131.61 233.265L130.657 234.388L130.31 234.763L130.397 235.137L131.264 236.073L131.871 236.822L133.258 238.413L134.212 239.537L136.293 241.97L136.986 242.813V243H132.911L131.957 241.877L131.177 240.847L130.397 239.911L129.616 238.881L128.749 237.852L127.969 236.822L127.449 236.167L127.362 242.906H124.24V221.377L124.327 221.283Z' />
      <path d='M113.782 227.206H115.562L116.968 227.477L118.093 227.928L118.936 228.47L119.686 229.101L120.342 230.004L120.904 231.177L121.185 232.26L121.279 232.982V243H117.999L117.905 242.91V234.336L117.718 232.711L117.249 231.628L116.406 230.726L115.469 230.275L114.719 230.094H113.126L111.908 230.455L111.158 230.997L110.502 231.719L110.127 232.621L109.94 233.524L109.846 243H106.566L106.472 242.91V227.477H109.846L109.94 229.101L110.689 228.379L112.001 227.657L113.22 227.296L113.782 227.206Z' />
      <path d='M177.655 221.283H181.494V242.906L177.874 243L177.545 242.906V221.377L177.655 221.283Z' />
      <path d='M186.539 221.283H190.378V242.906L186.758 243L186.429 242.906V221.377L186.539 221.283Z' />
      <path d='M99.5622 227.206H102.524V243H99.6445L99.5622 242.908V227.206Z' />
      <path d='M85.7425 227.206H88.7039V243H85.8248L85.7425 242.908V227.206Z' />
      <path d='M86.8393 221.283L88.0458 221.369L88.9232 221.798L89.5813 222.485L89.691 222.828V223.773L89.2523 224.459L88.3748 225.06L87.7167 225.232H86.6199L85.5231 224.803L84.8651 224.03L84.7554 223.773V222.742L85.1941 222.056L86.0715 221.455L86.8393 221.283Z' />
      <path d='M100.659 221.283L101.866 221.369L102.743 221.798L103.401 222.485L103.511 222.828V223.687L103.182 224.373L102.414 224.974L101.536 225.232H100.44L99.3429 224.803L98.6848 224.03L98.5751 223.773V222.742L99.0138 222.056L99.8913 221.455L100.659 221.283Z' />
    </SvgIcon>
  )
}
