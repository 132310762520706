import ArticleIcon from '@mui/icons-material/Article'
import TagIcon from '@mui/icons-material/Tag'

const sidebarItems = [
  {
    id: 'channels',
    text: 'Channels',
    icon: <TagIcon />,
    path: '/'
  },
  {
    id: 'documentation',
    text: 'Documentation',
    icon: <ArticleIcon />,
    path: '/documentation'
  }
]

export default sidebarItems
