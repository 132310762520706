import * as React from 'react'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { logout } from '../utils/auth'

import MenuContent from './MenuContent'

function SideMenuMobile ({ open, toggleDrawer }) {
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        [`& .${drawerClasses.paper}`]: {
          minWidth: '60vw',
          backgroundImage: 'none',
          backgroundColor: 'background.paper'
        }
      }}
    >
      <Stack
        sx={{
          maxWidth: '80dvw',
          height: '100%'
        }}
      >
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent />
          <Divider />
        </Stack>
        <Stack sx={{ p: 2 }}>
          <Button
            variant='outlined'
            fullWidth
            startIcon={<LogoutRoundedIcon />}
            onClick={logout}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default SideMenuMobile
