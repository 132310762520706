import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import MenuContent from './MenuContent'
import { Button } from '@mui/material'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import InkwellLogo from './Logo'
import { logout } from '../utils/auth'

const drawerWidth = 240

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box'
  }
})

export default function SideMenu () {
  return (
    <Drawer
      variant='permanent'
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 1.5,
          justifyContent: 'center'
        }}
      >
        <Box
          sx={(theme) => ({
            width: 96,
            height: 96,
            bgcolor: theme.palette.mode === 'light'
              ? 'background.default'
              : 'action.selected',
            borderRadius: 2
          })}
        >
          <InkwellLogo sx={{ fontSize: 96 }} />
        </Box>
      </Box>
      <Divider />
      <MenuContent />
      <Stack
        direction='row'
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Button
          variant='outlined'
          fullWidth
          startIcon={<LogoutRoundedIcon />}
          onClick={logout}
        >
          Logout
        </Button>
      </Stack>
    </Drawer>
  )
}
