import qs from 'qs'

const config = {
  apiServer: ''
}

const keyMap = {
  apiServer: 'api-server'
}

Object.keys(config).forEach((key) => {
  const mappedKey = keyMap[key] || key
  const lsVal = localStorage.getItem(mappedKey)

  if (lsVal) {
    config[key] = lsVal === 'true' ? true : lsVal === 'false' ? false : lsVal
  }

  const qsVal = qs.parse(window.location.search.slice(1))[mappedKey]
  if (qsVal) {
    const formattedValue =
      qsVal === 'true' ? true : qsVal === 'false' ? false : qsVal
    config[key] = formattedValue
    localStorage.setItem(mappedKey, formattedValue)
  }
})

export default config
