import * as React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { useMatches } from 'react-router-dom'
import sidebarItems from '../data/sidebar'

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: (theme.vars || theme).palette.action.disabled,
    margin: 1
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: 'center'
  }
}))

export default function NavbarBreadcrumbs () {
  const matches = useMatches()

  const item = React.useMemo(() => {
    const match = matches[1]
    return sidebarItems.find((item) => item.id === match?.id)
  }, [matches])

  return (
    <StyledBreadcrumbs
      aria-label='breadcrumb'
      separator={<NavigateNextRoundedIcon fontSize='small' />}
    >
      <Typography variant='body1'>Dashboard</Typography>
      <Typography
        variant='body1'
        sx={{ color: 'text.primary', fontWeight: 600 }}
      >
        {item?.text}
      </Typography>
    </StyledBreadcrumbs>
  )
}
